<template>
  <v-container class="fill-height">
    <div>
      <div class="error-code">404</div>
      <h1>Страница не найдена</h1>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "Page404",
  metaInfo: {
    title: "Страница не найдена",
  },
};
</script>

<style lang="scss">
.error-code {
  font-size: 10vw;
  line-height: 1;
}
</style>
